import React from "react"
import { withPrefix } from "gatsby"
import Micromark from "econify/components/Micromark"

import * as styles from "./Related.module.css"
import IRelatedProps from "./Related.types"

const Related = ({ title, value }: IRelatedProps) => {
  return (
    <>
      <Micromark as="h2" markdown={title.value} className={styles.title} />
      <div className={styles.imagesWrapper}>
        {value.map((item, index) => (
          <div key={index} className={styles.imageContainer}>
            <img
              className={styles.image}
              src={withPrefix(item.image.src)}
              alt={item.image.alt}
              loading="lazy"
            />
          </div>
        ))}
      </div>
      <button className={styles.button}>View all case studies</button>
    </>
  )
}

export default Related
