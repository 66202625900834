import React from "react"
import Micromark from "../../../components/Micromark"
import Underline from "../Underline/Underline"
import * as styles from "./Title.module.css"
import ITitle from "./Title.types"

const Title = ({ title, align, underline = false }: ITitle) => {
  const alignClass = styles[align]

  return (
    <div className={`${styles.titleContainer} ${alignClass}`}>
      <Micromark as="h2" markdown={title} className={styles.title} />
      {underline && <Underline />}
    </div>
  )
}

export default Title
