import React from "react"
import Micromark from "../../../components/Micromark"

import * as styles from "./Columns.module.css"
import IColumnProps from "./Columns.types"

const Columns = ({ value }: IColumnProps) => {
  return (
    <div className={styles.container}>
      {value.map((item, index) => {
        return (
          <div key={index} className={styles.column}>
            <Micromark className={styles.title} markdown={item.title} />
            <Micromark
              className={styles.body}
              markdown={item.body.join("\n\n")}
            />
          </div>
        )
      })}
    </div>
  )
}

export default Columns
