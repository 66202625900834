import React from "react"
import { Link } from "gatsby"

import Title from "../Title/Title"
import ILinkProps from "./CustomLink.types"

import * as styles from "./CustomLink.module.css"

const CustomLink = ({ title, value }: ILinkProps) => {
  const [first] = value
  return (
    <div>
      <Title title={title.value} align={title.align} />
      <div className={styles.linkContainer}>
        <Link className={styles.link} to={first.link.url}>
          {first.link.text}
        </Link>
      </div>
    </div>
  )
}

export default CustomLink
