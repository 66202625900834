import React from "react"
import { withPrefix } from "gatsby"

import * as styles from "./Images.module.css"
import IImageProps from "./Images.types"

const Images = ({ value }: IImageProps) => {
  return (
    <div className={styles.container}>
      {value.map((item, index) => {
        return (
          <div key={index} className={styles.imageContainer}>
            <img
              src={withPrefix(item.image.src)}
              alt={item.image.alt}
              loading="lazy"
              style={{ width: "100%" }}
            />
            <span className={styles.caption}>{item.image.caption}</span>
          </div>
        )
      })}
    </div>
  )
}

export default Images
