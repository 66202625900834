import React from "react"
import { withPrefix } from "gatsby"

import Micromark from "../../../components/Micromark"
import Title from "../Title/Title"
import * as styles from "./ListImage.module.css"
import IListImageProps from "./ListImage.types"

const ListImage = ({ title, value }: IListImageProps) => {
  const [listData, imageData] = value
  return (
    <>
      <Title title={title.value} align={title.align} underline />
      <div className={styles.columns}>
        <div className={styles.listColumn}>
          <Micromark className={styles.listTitle} markdown={listData.title} />
          <Micromark
            className={styles.list}
            markdown={listData.list.join(" \n")}
          />
        </div>
        <div className={styles.imageContainer}>
          <img
            className={styles.image}
            src={withPrefix(imageData.image.src)}
            alt={imageData.image.alt}
            loading="lazy"
          />
        </div>
      </div>
    </>
  )
}

export default ListImage
