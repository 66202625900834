import React from "react"
import { graphql, withPrefix } from "gatsby"
import * as styles from "./caseStudy.module.css"
import Simple from "./Simple/Simple"
import ListImage from "./ListImage/ListImage"
import Quotes from "./Quote/Quotes"
import Images from "./Images/Images"
import Columns from "./Columns/Columns"
import Related from "./Related/Related"
import CustomLink from "./CustomLink/CustomLink"
import Underline from "./Underline/Underline"

import ICaseStudyProps, { ComponentMapType } from "./caseStudy.types"

const ComponentMap: ComponentMapType = {
  simple: Simple,
  "list-image": ListImage,
  quote: Quotes,
  images: Images,
  columns: Columns,
  related: Related,
  link: CustomLink,
}

const CaseStudy = ({ data }: ICaseStudyProps) => {
  const caseStudy = data.caseStudiesJson

  return (
    <>
      <section className={styles.section}>
        <div className={styles.caseStudy}>Case Study / {caseStudy.title}</div>
        <div className={styles.tags}>{caseStudy.tags.join(", ")}</div>
        <div className={styles.row}>
          <img
            className={styles.companyLogo}
            src={withPrefix(caseStudy.companyLogo)}
            loading="lazy"
          />
          <p className={styles.summary}>{caseStudy.summary}</p>
        </div>
        <img
          alt=""
          className={styles.arrowLine}
          src={withPrefix("/images/line_arrow_b.svg")}
          id="clients"
        />
      </section>
      {caseStudy.sections.map((section, index) => {
        const Component = ComponentMap[section.component]
        return (
          Component && (
            <section className={styles.section}>
              <Component key={index} {...section} />
              <div className={styles.line} />
            </section>
          )
        )
      })}
      <section className={styles.section}>
        <p className={styles.paragraph}>hello@econify.com</p>
        <Underline
          className={styles.underline}
          style={{ marginTop: 24, marginBottom: 24 }}
        />
        <p className={styles.paragraph}>New York, NY (HQ)</p>
        <p className={styles.paragraph}>Austin, TX</p>
        <p className={styles.paragraph}>Birmingham, UK</p>
      </section>
    </>
  )
}

export default CaseStudy

export const query = graphql`
  fragment Image on CaseStudiesJsonSectionsValueImage {
    src
    alt
    caption
  }

  fragment ListImage on CaseStudiesJsonSectionsValue {
    title
    list
    image {
      ...Image
    }
  }

  fragment Quote on CaseStudiesJsonSectionsValue {
    quote
  }

  fragment Lists on CaseStudiesJsonSectionsValue {
    title
    list
  }

  fragment Columns on CaseStudiesJsonSectionsValue {
    title
    body
  }

  fragment Link on CaseStudiesJsonSectionsValue {
    link {
      text
      url
    }
  }

  query ($caseStudyId: String!) {
    caseStudiesJson(id: { eq: $caseStudyId }) {
      title
      tags
      companyLogo
      summary
      sections {
        component
        title {
          value
          align
        }
        value {
          ...Lists
          ...ListImage
          ...Quote
          ...Columns
          ...Link
        }
      }
      parent {
        ... on File {
          name
        }
      }
    }
  }
`
