import React from "react"
import Micromark from "../../../components/Micromark"
import Title from "../Title/Title"
import ISimpleProps from "./Simple.types"

import * as styles from "./Simple.module.css"

const Lists = ({ title, value }: ISimpleProps) => {
  return (
    <div className={styles.container}>
      {title && <Title title={title.value} align={title.align} underline />}
      <div className={styles.columns}>
        {value.map((item, index) => (
          <div className={styles.column}>
            <Micromark
              className={styles.listTitle}
              key={index}
              markdown={item.title}
            />
            <Micromark
              className={styles.list}
              key={index}
              markdown={item.list.join(" \n")}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Lists
