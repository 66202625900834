// extracted by mini-css-extract-plugin
export var arrowLine = "caseStudy-module--arrowLine--45ce2";
export var caseStudy = "caseStudy-module--caseStudy--14a39";
export var companyLogo = "caseStudy-module--companyLogo--b082a";
export var line = "caseStudy-module--line--ad9f0";
export var paragraph = "caseStudy-module--paragraph--c0fe0";
export var row = "caseStudy-module--row--6d041";
export var section = "caseStudy-module--section--f648d";
export var summary = "caseStudy-module--summary--fa57e";
export var tags = "caseStudy-module--tags--d7d15";
export var underline = "caseStudy-module--underline--65bc6";