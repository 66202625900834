import React from "react"
import Micromark from "../../../components/Micromark"
import * as styles from "./Quotes.module.css"
import IQuoteProps from "./Quote.types"

const Quotes = ({ value }: IQuoteProps) => {
  return (
    <div>
      {value.map((item, index) => {
        const quote = item.quote.join("\n")
        return (
          <Micromark className={styles.quote} key={index} markdown={quote} />
        )
      })}
    </div>
  )
}

export default Quotes
