import React from "react"

import * as styles from "./Underline.module.css"

interface IUnderlineProps {
  className?: string
  style: React.CSSProperties
}

const Underline = ({ className, style }: IUnderlineProps) => {
  return (
    <hr
      style={style}
      className={[styles.hr, styles.fadeInLeft, className].join(" ")}
    />
  )
}

export default Underline
